import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchServices } from '../rtk/slices/services-slice'
import { fetchColor } from '../rtk/slices/color-slice'
import { fetchProjects } from '../rtk/slices/projects-slice'
import { useTranslation } from 'react-i18next'
import { fetchProjects_All } from '../rtk/slices/projects_all-slice'

function AllProjects () {
    const color = useSelector((state) => state.color.color);
    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects_all.projects_all);
    const [servicesSearch, setServicesSearch] = useState(projects);

    const { t, i18n } = useTranslation();


    const projects_ar = projects.map(gal_ar => {
        if (i18n.language == "ar") {
            return ({
                "id": gal_ar.id,
                "img": gal_ar.img,
                "name": gal_ar.name_ar,
            })
        }
        return gal_ar;
    })

    const filterService = (searchKeyword) => {
        const updateData = projects.filter((service) => service.title.includes(searchKeyword));
        setServicesSearch(updateData)
    }

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }

    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        if (sessionStorage.getItem("lang") === "en") {
            document.body.style.direction = "ltr";
        } else {
            document.body.style.direction = "rtl";
        }

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchProjects_All())

    }, [])

    return (
        <section className='projects ' id='projects'>
            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "المشاريع" : "Projects"}</span>
                    <p>{i18n.language == "ar" ? "المشاريع" : "Projects"}</p>
                </h2>

                <div className="cards">

                    {
                        projects_ar.map((card, index) => {
                            return (
                                <Link to={`/projects/project/${card.id}`} className="card" key={card.id} style={{ textAlign: "center", height: "330px" }}>
                                    <img style={{ width: "100%", maxWidth: "19rem", maxHeight: "13rem", borderRadius: ".5rem" }} src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${card.img}`} alt="" />
                                    <h4 style={{ paddingTop: "1rem" }}>{card.name}</h4>
                                </Link>
                            )
                        })
                    }

                </div>

                <Link className='exit' to={`/`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default AllProjects
