import React, { useEffect, useState } from 'react'
import { Link, useParams, useHistory, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchColor } from '../rtk/slices/color-slice';
import { fetchServices } from '../rtk/slices/services-slice';
import { fetchProjects } from '../rtk/slices/projects-slice';
import { useTranslation } from 'react-i18next';
import { fetchProjects_All } from '../rtk/slices/projects_all-slice';

function ProjectPage () {
    window.scrollTo(0, 0); // العودة إلى الجزء العلوي
    const { id } = useParams();

    const navigate = useNavigate();
    // توجيه المستخدم إلى القسم "Contact"
    const handleButtonClick = () => {
        navigate('/#contact');
    };

    const total30 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30]


    const dispatch = useDispatch();
    const projects = useSelector((state) => state.projects_all.projects_all);
    const color = useSelector((state) => state.color.color);

    const { t, i18n } = useTranslation();


    const gallery_ar = projects.map(gal_ar => {
        if (i18n.language == "ar") {
            return ({
                "id": gal_ar.id,
                "name": gal_ar.name_ar,
                "link": gal_ar.link,
                "img": gal_ar.ima,
                "img_project1": gal_ar.img_project1,
                "caption_img_project1": gal_ar.caption_img_project1_ar,

                "img_project2": gal_ar.img_project2,
                "caption_img_project2": gal_ar.caption_img_project2_ar,

                "img_project3": gal_ar.img_project3,
                "caption_img_project3": gal_ar.caption_img_project3_ar,

                "img_project4": gal_ar.img_project4,
                "caption_img_project4": gal_ar.caption_img_project4_ar,

                "img_project5": gal_ar.img_project5,
                "caption_img_project5": gal_ar.caption_img_project5_ar,

                "img_project6": gal_ar.img_project6,
                "caption_img_project6": gal_ar.caption_img_project6_ar,

                "img_project7": gal_ar.img_project7,
                "caption_img_project7": gal_ar.caption_img_project7_ar,

                "img_project8": gal_ar.img_project8,
                "caption_img_project8": gal_ar.caption_img_project8_ar,

                "img_project9": gal_ar.img_project9,
                "caption_img_project9": gal_ar.caption_img_project9_ar,

                "img_project10": gal_ar.img_project10,
                "caption_img_project10": gal_ar.caption_img_project10_ar,

                "img_project11": gal_ar.img_project11,
                "caption_img_project11": gal_ar.caption_img_project11_ar,

                "img_project12": gal_ar.img_project12,
                "caption_img_project12": gal_ar.caption_img_project12_ar,

                "img_project13": gal_ar.img_project13,
                "caption_img_project13": gal_ar.caption_img_project13_ar,

                "img_project14": gal_ar.img_project14,
                "caption_img_project14": gal_ar.caption_img_project14_ar,

                "img_project15": gal_ar.img_project15,
                "caption_img_project15": gal_ar.caption_img_project15_ar,

                "img_project16": gal_ar.img_project16,
                "caption_img_project16": gal_ar.caption_img_project16_ar,

                "img_project17": gal_ar.img_project17,
                "caption_img_project17": gal_ar.caption_img_project17_ar,

                "img_project18": gal_ar.img_project18,
                "caption_img_project18": gal_ar.caption_img_project18_ar,

                "img_project19": gal_ar.img_project19,
                "caption_img_project19": gal_ar.caption_img_project19_ar,

                "img_project20": gal_ar.img_project20,
                "caption_img_project20": gal_ar.caption_img_project20_ar,

            })
        }
        return gal_ar;
    })

    if (color.length > 0) {
        let inputString = color[0].color;
        let filteredValue = inputString.replace(/[^0-9,]/g, '');

        // تعيين قيمة المتغير --main-color في :root
        const root = document.documentElement;
        root.style.setProperty("--main-color", `${filteredValue}`);
    }


    useEffect(() => {
        if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"))
        }
        if (sessionStorage.getItem("lang") === "en") {
            document.body.style.direction = "ltr";
        } else {
            document.body.style.direction = "rtl";
        }

        // fetch color
        dispatch(fetchColor());

        // fetch services
        dispatch(fetchProjects_All());

    }, [])

    return (
        <section className='projects service pageOver' id='projects'>

            <div className="container">
                <h2 className="title">
                    <span>{i18n.language == "ar" ? "مشروع" : "Project"}</span>
                    <p style={{ textTransform: "capitalize" }}>{i18n.language == "ar" ? "مشروع" : "Project"}</p>
                </h2>

                {gallery_ar.map((service) => {
                    return (
                        service['id'] == id ?
                            <div className="card-service">
                                {
                                    total30.map((number) => {
                                        const imgKey = `img_project${number}`;
                                        const captionKey = `caption_img_project${number}`;

                                        if (service[imgKey] && service[imgKey].length > 4) {
                                            return (
                                                <div className="content">
                                                    <div style={{ margin: "2rem auto" }} className="img">
                                                        <img src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${service[imgKey]}`} alt="" />
                                                    </div>
                                                    <span style={{ display: "block" }} className='priceSpan'>{service[captionKey]}</span><br />
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                                {service.link.length > 5 &&
                                    <>
                                        <div className="video" style={{ textAlign: "center" }}>
                                            <button class="button type1"><span class="btn-txt"><a target='_blank' className='adownload' href={service.link} >{i18n.language == "ar" ? "لينك المشروع" : "Project Link"}</a></span></button>
                                        </div>
                                        <br />
                                    </>
                                }
                            </div>
                            : null
                    )
                })}


                <Link className='exit' to={`/projects`}>
                    <div class="menu active">
                        <span class="span1"></span>
                        <span class="span2"></span>
                        <span class="span3"></span>
                    </div>
                </Link>
            </div>
        </section>
    )
}

export default ProjectPage
