import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchProjects_All = createAsyncThunk('projects_allSlice/fetchProjects_All', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?projects_all`)
    const data = await res.json()
    return data;
})

const projects_allSlice = createSlice({
    initialState: {
        projects_all: [],
        loading: false,
        error: null,
    },
    name: 'projects_allSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects_All.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchProjects_All.fulfilled, (state, action) => {
                state.loading = false;
                state.projects_all = action.payload;
            })
            .addCase(fetchProjects_All.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = projects_allSlice.actions;

export default projects_allSlice.reducer;