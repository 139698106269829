import React from 'react'
import loading from '../assest/loading.gif'

function Loader () {
    return (
        <div className="loader">
            <div class="honeycomb">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Loader
