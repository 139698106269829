import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchServices = createAsyncThunk('servicesSlice/fetchServices', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?services`)
    const data = await res.json()
    return data;
})

const servicesSlice = createSlice({
    initialState: {
        services: [],
        loading: false,
        error: null,
    },
    name: 'servicesSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchServices.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchServices.fulfilled, (state, action) => {
                state.loading = false;
                state.services = action.payload;
            })
            .addCase(fetchServices.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = servicesSlice.actions;

export default servicesSlice.reducer;