import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchVisitor = createAsyncThunk('visitorSlice/fetchVisitor', async () => {
    const res = await fetch(`${process.env.REACT_APP_PROJECTS_API}components/s_d.php?visitor`)
    const data = await res.json()
    return data;
})

const visitorSlice = createSlice({
    initialState: {
        visitor: [],
        loading: false,
        error: null,
    },
    name: 'visitorSlice',
    extraReducers: (builder) => {
        builder
            .addCase(fetchVisitor.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchVisitor.fulfilled, (state, action) => {
                state.loading = false;
                state.visitor = action.payload;
            })
            .addCase(fetchVisitor.rejected, (state, action) => {
                state.loading = false;
                state.error = <p className='error-api'>The server is temporarily down Please try later!</p>;
            });
    },
})

export const { } = visitorSlice.actions;

export default visitorSlice.reducer;