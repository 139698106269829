
import All from "./components/All";
// import All_certificates from "./components/All_certificates";
import { BrowserRouter as Router, Route, Switch, Routes, BrowserRouter } from 'react-router-dom';
import Service from "./components/Service";
import Blog from "./components/Blog";
import All_Blogs from "./components/All_Blogs";
import Package from "./components/Package";
import AllProjects from "./components/AllProjects";
import ProjectPage from "./components/ProjectPage";
// import Service from "./components/Service";


function App () {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<All />} />
          <Route path="/service/:id" element={<Service />} />
          <Route path="/package/:category" element={<Package />} />
          <Route path="/projects" element={<AllProjects />} />
          <Route path="/projects/project/:id" element={<ProjectPage />} />
          <Route path="/blogs" element={<All_Blogs />} />
          <Route path="/blogs/blog/:id" element={<Blog />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
